import { createApp } from 'vue';
// bootstrap
import { BootstrapVueNext, vBTooltip } from 'bootstrap-vue-next';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-next/dist/bootstrap-vue-next.css';
// icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
// Auth
import Keycloak from 'keycloak-js';
import ACL from '@/helpers/acl';
// multi language support
import i18n from './i18n';
// app
import App from './App.vue';
import Error from './Error.vue';
import router from './router';

// error page
function showErrorPage() {
  const app = createApp(Error)
    .use(i18n)
    .use(BootstrapVueNext);
  app.mount('#app');
}

const metadataBackend = require('@inNETMonitoring/metadata-clients');
const dataBackend = require('@inNETMonitoring/data-clients');
const convertBackend = require('@inNETMonitoring/convert-clients');
const loRaDownlinkBackend = require('@inNETMonitoring/lora-downlink-clients');

const metaApiClient = metadataBackend.ApiClient.instance;
metaApiClient.basePath = process.env.VUE_APP_META_API_ENDPOINT;

const dataApiClient = dataBackend.ApiClient.instance;
dataApiClient.basePath = process.env.VUE_APP_DATA_API_ENDPOINT;

const convertApiClient = convertBackend.ApiClient.instance;
convertApiClient.basePath = process.env.VUE_APP_CONVERT_API_ENDPOINT;

const loRaDownlinkApiClient = loRaDownlinkBackend.ApiClient.instance;
loRaDownlinkApiClient.basePath = process.env.VUE_APP_LORADOWNLINK_API_ENDPOINT;

const acl = ACL.getInstance();

// Keycloak JS Stuff
const keycloakInitOptions = {
  url: process.env.VUE_APP_KEYCLOAK_URL,
  realm: process.env.VUE_APP_KEYCLOAK_REALM,
  clientId: process.env.VUE_APP_KEYCLOAK_CLIENTID,
  onLoad: 'login-required',
  responseMode: 'query',
};

const keycloak = new Keycloak(keycloakInitOptions);

keycloak.init({ onLoad: keycloakInitOptions.onLoad }).then((auth) => {
  if (!auth) window.location.reload();

  setInterval(() => {
    keycloak.updateToken(70).then((refreshed) => {
      if (refreshed) {
        metaApiClient.authentications.innet_sso.accessToken = keycloak.token;
        dataApiClient.authentications.innet_sso.accessToken = keycloak.token;
        convertApiClient.authentications.innet_sso.accessToken = keycloak.token;
        loRaDownlinkApiClient.authentications.innet_sso.accessToken = keycloak.token;
        acl.setToken(keycloak.token).then();
      }
    }).catch((err) => {
      console.error('Failed to refresh token', err);
      showErrorPage();
    });
  }, 60000);
  metaApiClient.authentications.innet_sso.accessToken = keycloak.token;
  dataApiClient.authentications.innet_sso.accessToken = keycloak.token;
  convertApiClient.authentications.innet_sso.accessToken = keycloak.token;
  loRaDownlinkApiClient.authentications.innet_sso.accessToken = keycloak.token;
  acl.setToken(keycloak.token).then(() => {
    const app = createApp(App)
      .component('font-awesome-icon', FontAwesomeIcon)
      .use(i18n)
      .use(router)
      .use(BootstrapVueNext)
      .directive('b-tooltip', vBTooltip);

    app.config.globalProperties.$metadataBackend = metadataBackend;
    app.config.globalProperties.$dataBackend = dataBackend;
    app.config.globalProperties.$convertBackend = convertBackend;
    app.config.globalProperties.$loRaBackend = loRaDownlinkBackend;
    app.mount('#app');
  }).catch((err) => {
    console.error('Authentication failed', err);
    showErrorPage();
  });
}).catch((err) => {
  console.error('Error', err);
  showErrorPage();
});
