<template>
  <div id="app">
    <b-navbar toggleable="lg" variant="dark">
      <b-navbar-brand href="/sites">
        <img id="brand-logo" :src="brandLogo" alt="" />
        <div id="brand-title" class="d-none d-md-inline"> {{ this.title }}</div>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" v-model="menuShown" is-nav>
        <b-navbar-nav class="ms-auto">
          <router-link class="nav-link" v-if="hasSitePageAccess" to="/sites" @click="collapseNavBar()">
            <font-awesome-icon icon="location-dot" />
            {{ t('label.sites') }}
          </router-link>
          <router-link class="nav-link" v-if="hasLoggerPageAccess" to="/loggers" @click="collapseNavBar()">
            <font-awesome-icon icon="satellite-dish" />
            {{ t('label.loggers') }}
          </router-link>
          <router-link class="nav-link" v-if="hasGroupPageAccess" to="/admin" @click="collapseNavBar()">
            <font-awesome-icon icon="lock" />
            {{ t('label.admin') }}
          </router-link>
          <router-link class="nav-link" v-if="hasWarningPageAccess" to="/warning" @click="collapseNavBar()">
            <font-awesome-icon icon="bell" />
            {{ t('label.warning') }}
          </router-link>
          <b-nav-item-dropdown v-if="hasDatamodificationPageAccess" :text="t('label.datamodification')" right>
            <b-dropdown-item>
              <router-link class="nav-link" to="/dataImport" @click="collapseNavBar()">
                <font-awesome-icon icon="file-import" />
                {{ t('label.dataImport') }}
              </router-link>
            </b-dropdown-item>
            <b-dropdown-item>
              <router-link class="nav-link" v-if="enableDataCorrection" to="/dataCorrection" @click="collapseNavBar()">
                <font-awesome-icon icon="arrow-down-up-across-line" />
                {{ t('label.dataCorrection') }}
              </router-link>
            </b-dropdown-item>
          </b-nav-item-dropdown>
          <router-link class="nav-link" v-if="hasDatacontrolPageAccess" to="/dataControl" @click="collapseNavBar()">
            <font-awesome-icon icon="list-check" />
            {{ t('label.dataControl') }}
          </router-link>
          <router-link class="nav-link" v-if="enableDataCorrection" to="/helperVue"
                       @click="collapseNavBar()">
              <font-awesome-icon icon="circle-info"/>
              {{ t('label.helperPage') }}
          </router-link>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <router-view />
    <div id="footer">

      <div class="copyright">
        &copy; {{ currentYear }} inNET Monitoring AG
      </div>
      <div class="appVersion">v{{ appVersion }}</div>
      <div class="footer-links">
        <router-link to="/impressum">
          Impressum
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import { useI18n } from 'vue-i18n';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHouse, faCircleInfo, faLock, faLocationDot, faSatelliteDish, faBell, faArrowUp, faArrowDown,
  faListCheck, faAnglesDown, faAnglesUp, faArrowDownUpAcrossLine, faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import ACL from '@/helpers/acl';
import { version } from '../package.json';

library.add(
  faHouse,
  faCircleInfo,
  faLock,
  faLocationDot,
  faSatelliteDish,
  faBell,
  faArrowUp,
  faArrowDown,
  faListCheck,
  faAnglesDown,
  faAnglesUp,
  faArrowDownUpAcrossLine,
  faFileImport,
);

const app = {
  setup() {
    const { t } = useI18n({
      inheritLocale: true,
      useScope: 'local',
    });
    return { t };
  },
  data() {
    return {
      title: process.env.VUE_APP_CUSTOM_PAGE_TITLE,
      enableDataCorrection: process.env.VUE_APP_ENABLE_DATACORRECTION,
      menuShown: false,
      appVersion: version,
    };
  },
  computed: {
    brandLogo() {
      return `${process.env.BASE_URL}${process.env.VUE_APP_CUSTOM_PAGE_LOGO}`;
    },
    currentYear() {
      return (new Date()).getFullYear();
    },
    hasAdminPageAccess() {
      return ACL.getInstance().hasAdministrationPageAccess();
    },
    hasWarningPageAccess() {
      return ACL.getInstance().hasWarningPageAccess();
    },
    hasSitePageAccess() {
      return ACL.getInstance().hasReadAccessOnSites();
    },
    hasLoggerPageAccess() {
      return ACL.getInstance().hasReadAccessOnLoggers();
    },
    hasDatamodificationPageAccess() {
      return ACL.getInstance().hasWriteAccessOnDatamodification();
    },
    hasDatacontrolPageAccess() {
      return ACL.getInstance().hasWriteAccessOnDatacontrol();
    },
    hasGroupPageAccess() {
      return ACL.getInstance().hasWriteAccessOnGroup();
    },
  },
  methods: {
    collapseNavBar() {
      this.menuShown = false;
    },
  },
};

export default app;
</script>

<style lang=scss>

@import '~bootstrap/scss/bootstrap';

#brand-logo {
  margin-left: 0.75rem;
}

#brand-title {
  margin-left: 0.75rem;
  color: $light;
}

.table-container {
  overflow-x: auto;
}

.navbar-nav .nav-link {
  color: $navLinkColour !important;
}

.navbar-nav .nav-link:hover {
  color: $navLinkColourHover !important;
}

.nav-item .dropdown-menu {
  background-color: $dark !important;
}

.nav-item .dropdown-menu .dropdown-item:hover {
  background-color: $dark !important;
  color: $navLinkColourHover !important;
}

.nav-item .dropdown-menu .dropdown-item {
  color: $light !important;
}

.nav-item .btn-group {
  display: block;
}

.navbar-toggler {
  background-color: $light !important;
}
</style>
<i18n>
{
  "en": {
    "label": {
      "admin": "Administration",
      "warning": "Warning",
      "dataControl": "Data Control",
      "dataCorrection": "Data Correction",
      "dataImport": "Data Import",
      "sites": "Sites",
      "loggers": "Loggers",
      "helperPage": "Help",
      "datamodification": "Datamodification"
    }
  },
  "de": {
    "label": {
      "admin": "Administration",
      "warning": "Warnungen",
      "dataControl": "Datenkontrolle",
      "dataCorrection": "Datenkorrektur",
      "dataImport": "Datenimport",
      "sites": "Standorte",
      "loggers": "Logger",
      "helperPage": "Hilfe",
      "datamodification": "Datenmodifikation"
    }
  }
}
</i18n>
